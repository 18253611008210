import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { sidebarItem } from "./SideBardata";
import SubMenu from "./SideBarMenu";
import jwt_decode from "jwt-decode";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "../Common/withRouter";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";
import { DropdownMenu } from "reactstrap";
import { getMenusOptions } from "../../store/menu/actions";
import { getLocalbody } from "../../helpers/globalFunctions";
import { get } from "../../helpers/api_helper";

const SidebarContent = props => {
  const ref = useRef();
  const dispatch = useDispatch();
  const menuOptions = useSelector((state) => state.Menus.menuOptions);
  const [userId, setUserId] = useState(1);
  const [request, setrequest] = useState("")

  useEffect(() => {
    var data = localStorage.getItem("authUser");
    dispatch(getMenusOptions(JSON.parse(data)._id));
  }, [dispatch]);

  const path = window.location.pathname
  console.log(path, "pathname");

  function getAllRequestCount() {
    get(`complaint/active/edit-request/count?localbodyId=${getLocalbody()}`).then((res) => {
      setrequest(res.count)
    }).catch((err) => {
    })
  }


  useEffect(() => {
    getAllRequestCount()
  }, [path])


  useEffect(() => {
    // Function to call the API
    const fetchData = () => {
      getAllRequestCount();
    };
  
    // Call the function immediately when the component mounts or `path` changes
    fetchData();
  
    // Set up an interval to call the function every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(fetchData, 300000);
  
    // Clean up the interval when the component unmounts or `path` changes
    return () => clearInterval(intervalId);
  }, [path]);



  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: "100%" }}
        ref={ref}
        className="sidebar-menu-scroll"
      >
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled " id="side-menu">
            {menuOptions &&
              menuOptions.map((item, index) => {
                return <SubMenu request={request} item={item} key={index} />;
              })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};
// SidebarContent.propTypes = {
//   menuOptions: PropTypes.array.isRequired
// };

export default withRouter(withTranslation()(SidebarContent));
