
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import io from 'socket.io-client';
import { getLocalbody } from "../../helpers/globalFunctions";
const SubMenu = ({ item,request }) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [subnav, setSubnav] = useState(false);
  const localbodyId = getLocalbody()

  const [requestCount, setRequestCount] = useState(0);

  const [messages, setMessages] = useState([]);
  const socket = React.useRef(null);

  // useEffect(() => {
  //   if (!socket.current) {
  //     socket.current = io(`${API_URL}`, {
  //       transports: ['websocket'],
  //       query: { localbodyId },
  //       reconnectionAttempts: 5,
  //       reconnectionDelay: 2000,
  //     });
  //   }

  //   socket.current.on('connect', () => socket.current.emit('join-room', localbodyId));
  //   socket.current.on('request-update', (data) => {
  //     try {
  //       const parsedData = JSON.parse(data);
  //       if (parsedData.localbodyId === localbodyId) setRequestCount(parsedData.count);
  //     } catch {
  //       setMessages('An unknown error occurred.');
  //     }
  //   });
  //   socket.current.on('error', (message) => {
  //     try {
  //       const parsedData = JSON.parse(message);
  //       setMessages(parsedData.message);
  //     } catch {
  //       setMessages('An unknown error occurred.');
  //     }
  //   });

  //   const cleanup = () => {
  //     if (socket.current && socket.current.connected) {
  //       socket.current.emit('leave-room', localbodyId);
  //       socket.current.disconnect();
  //     }
  //   };

  //   // window.addEventListener('beforeunload', cleanup);
  //   // window.addEventListener('unload', cleanup);

  //   return () => {
  //     cleanup();
  //     // window.removeEventListener('beforeunload', cleanup);
  //     // window.removeEventListener('unload', cleanup);
  //   };
  // }, []);


  const showSubnav = () => setSubnav(!subnav);
  const hideAll = () => {
    $(".sub-menu").hide();
    setSubnav(false);
  };


  return (
    // <ul className="metismenu list-unstyled " id="side-menu">
    <li>
      <Link
        to={item.main_link !== null ? item.main_link : null}
        onClick={item.submenu.length > 0 && showSubnav}
      >
        <div
          className={`wave-effect ${item.submenu.length > 0 ? "has-arrow" : null
            }`}
          onClick={hideAll}
        >
          <i className={item.main_icon}></i>
          <span>{item.main_menuname}</span>
          {item?._id=="67972e59850f28384f4f484b" &&  <span style={{fontSize:"14px",fontWeight:"600",color:"red"}} className="mx-2">({request})</span>}
         
        </div>
      </Link>
      <ul>
        {subnav &&
          item.submenu.map((item, index) => {
            return (
              <li className="sub-menu">
                <Link to={item.sub_link} key={index} className="waves-effect">
                  <i className={item.sub_icon}></i>
                  <span className="sub-menu">{item.sub_name}</span>
                </Link>
              </li>
            );
          })}
      </ul>
    </li>
    // </ul>
  );
};

export default SubMenu;
