import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Input } from "reactstrap";
import { Modal } from "react-bootstrap";
import LoopIcon from "@mui/icons-material/Loop";
import Select from "react-select";

import { CSVLink } from "react-csv";
import { apiError } from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./receipt.scss";
import { getLocalbody, getDate, getFirstday, getArraySumByKey, formatMoney, inWords } from "../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import $ from "jquery";
import logoSm from "../../assets/images/logo-sm.svg";
import logoDark from "../../assets/images/logo-dark.svg";
import toastr from "toastr";
import moment from "moment";
// import { query } from "chartist";
import saveAs from "file-saver";
import DataTable from "../../components/Common/DataTable";
import qs from 'querystring'
import { jsonToExcel } from "../../helpers/api_helper";
import LoadingButton from "../../components/Common/LoadingButton";
import { set } from "lodash";
//import logoLight from "../../assets/images/logo-light.svg";


const DeletedReceipt = () => {
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [receiptsForTable, setReceiptsForTable] = useState([]);
  const [form, setForm] = useState(false);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [remarks, setremarks] = useState("")
  const [userId, setUserId] = useState(1);
  const [grandTotal, setGrandTotal] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [popupData, setPopupData] = useState({});
  const [wasteItems, setWasteItems] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [receiptIdTobeUpdated, setReceiptIdTobeUpdated] = useState(null);
  const [totalReceiptAmount, settotalReceiptAmount] = useState("")
  const [master, setMaster] = useState({
    receipt_type: 0,
  });
  const [errors, setErrors] = useState({
    customerError: "",
    paymodeError: "",
  });
  const [showDeleteReceipt, setShowDeleteReceipt] = useState(false);
  const [receiptOtpData, setReceiptOtpData] = useState({});
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(20)

  const [isExporting, setIsExporting] = useState(false)
  const [disabled, setdisabled] = useState(false)


  const formRef = useRef();
  useEffect(() => {
    fetch_all_staff(currentLocalbody);
    fetch_all_wards(currentLocalbody);
    fetch_all_customers(currentLocalbody, "");
  }, [currentLocalbody]);

  useEffect(() => {
    handleTableData();
  }, [currentLocalbody, page, limit, searchData])

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);

      setUserId(obj._id);
      setMaster({
        ...master,
        user_id: obj._id,
      });
    }
  }, [userId]);

  const fetch_all_customers = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: localbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            let name = customer?.cust_reg_no + "-" + customer?.cust_name + "-" + customer?.cust_phone + "-" + customer?.cust_house_num;
            return {
              label: name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const handleCustomerSearch = (query) => {
    let data = {
      keyword: query,
      localbody_id: currentLocalbody,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            let name = customer?.cust_reg_no + "-" + customer?.cust_name + "-" + customer?.cust_phone + "-" + customer?.cust_house_num;
            return {
              label: name,
              value: customer?._id,
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}receipt/singlelist?receipt_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        if (res.data.status === 200) {
          result.receipt &&
            result.receipt.map((element) => {
              element.receipt_cust_detail &&
                element.receipt_cust_detail.map((customer) => {
                  result.cust_address = customer?.cust_address;
                  result.cust_house_num = customer?.cust_house_num;
                  result.cust_phone = customer?.cust_phone;
                  result.cust_reg_no = customer?.cust_reg_no;
                  result.shop_name = customer?.shop_name;
                });
              element.receipt_addedby.map((el) => {
                result.addedBy = el?.firstName;
              });
              element.receipt_localbody.map((el) => {
                result.localbodyName = el?.localbody_name;
                result.localbodyType = el?.localbody_type;
                result.localbody_Name = el?.localbody_name.toUpperCase();
                result.localbody_Type = el?.localbody_type.toUpperCase();
              });
              element.receipt_ward_id.map((el) => {
                result.wardName = el?.ward_name;
              });

              //amount to words
              let amountinwords = inWords(element?.reciept_amount);

              //capitalize first digit
              const arr = amountinwords.split(" ");
              for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
              }
              const str2 = arr.join(" ");

              // result.amountinwords =  amountinwords.charAt(0).toUpperCase() + amountinwords.slice(1);
              result.amountinwords = str2;

              result.receipt_customer_name = element?.receipt_customer_name;
              result.receipt_date = element?.receipt_date;
              result.reciept_amount = element?.reciept_amount.toFixed(2);
              result.receipt_no = element?.receipt_no;
              if (element?.receipt_deleted_date) {
                result.receipt_deleted_dates = moment(element?.receipt_deleted_date).format("DD-MM-YYYY");
              }

              result.receipt_time = moment(element?.receipt_time, "HHmmss").format("hh:mm a");
              if (element?.receipt_fixed_charge) {
                result.receipt_fixed_charge = element?.receipt_fixed_charge.toFixed(2);
              } else result.receipt_fixed_charge = "0.00";

              if (element?.receipt_due_amt) {
                result.receipt_due_amt = element?.receipt_due_amt.toFixed(2);
              } else result.receipt_due_amt = "0.00";

              if (element?.receipt_tot_current_usage) {
                result.receipt_tot_current_usage = element?.receipt_tot_current_usage.toFixed(2);
              } else result.receipt_tot_current_usage = "0.00";

              if (element?.receipt_tot_due) {
                result.receipt_tot_due = element?.receipt_tot_due.toFixed(2);
              } else result.receipt_tot_due = "0.00";

              if (element?.receipt_prev_due) {
                result.receipt_prev_due = element?.receipt_prev_due.toFixed(2);
              } else result.receipt_prev_due = "0.00";
            });
          let prevInvoice = result.previous_invoice || {};
          // result.previous_invoice &&
          //   result.previous_invoice.map((el) => {
          result.invoice_no = prevInvoice?.invoice_no ? prevInvoice.invoice_no : "";
          result.invoice_date = prevInvoice?.invoice_date ? moment(prevInvoice?.invoice_date).format("DD-MM-YYYY") : "";
          result.periodFrom = prevInvoice?.invoice_bill_dur_from ? moment(prevInvoice?.invoice_bill_dur_from).format("DD-MM-YYYY") : "";
          result.periodTo = prevInvoice?.invoice_bill_dur_to ? moment(prevInvoice?.invoice_bill_dur_to).format("DD-MM-YYYY") : "";
          result.invoice_total_amt = prevInvoice?.invoice_total_amt ? prevInvoice?.invoice_total_amt.toFixed(2) : "";
          // });
          let data = result.waste_collections || [];
          data &&
            data.map((item, index) => {
              item.id = index + 1;

              item.waste_clt_item_price = item?.waste_clt_item_price.toFixed(2);
              if (item.waste_clt_item_paid_status === 0) {
                item.waste_clt_item_name = item.waste_clt_item_name + "(Paid)";
                let amount = -item?.waste_clt_item_amt;
                item.waste_clt_item_amt = amount.toFixed(2);
              } else item.waste_clt_item_amt = item?.waste_clt_item_amt.toFixed(2);
            });
          setWasteItems(data);
          setPopupData(result);
          setForm(true);
        }
      });
    // setForm(true);
  };

  const handleCloseForm = () => {
    // setWasteItems([]);
    // setPopupData({});
    setForm(false);
  };



  const handleExport = async () => {
    var url = `${API_URL}receipt/list?localbody_id=${currentLocalbody}&page=${1}&limit=${count}&${qs.stringify(searchData)}`;
    setIsExporting(true)
    await axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        setCount(res.data.count)
        let dataToBeExported = [];
        result.map((item, index) => {
          let exportItem = {};

          item.id = (page - 1) * limit + index + 1;
          //export data
          exportItem.id = item.id;
          exportItem.Date = item.receipt_date;
          exportItem.Receipt_No = item.receipt_no;
          exportItem.Customer_Name = item.receipt_customer_name;
          exportItem.ID = item.customerId;
          exportItem.Mobile = item.custPhone;
          //exportItem.Group = item.group;
          exportItem.Ward = item.customerward;
          exportItem.Amount = item.reciept_amount;
          exportItem.Due = item.receipt_due_amt;
          exportItem.Staff = item.staff;

          dataToBeExported.push(exportItem);

          return item
        });

        jsonToExcel(dataToBeExported, "Receipts");
      }).finally(() => {
        setIsExporting(false)
      });

  }

  const showDeleteForm = (item) => {
    let cust_id = "";
    let val = item?.receipt_cust_detail || [];
    val &&
      val.map((el) => {
        cust_id = el?._id;
      });
    let data = {
      customer_id: cust_id,
      localbody_id: currentLocalbody,
    };

    axios
      .post(`${API_URL}api/staff/receipt_otp`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("OTP Send Successfully");
          setReceiptOtpData({
            otp_gen: res.data.otp,
            cust_phone: item?.custPhone,
            receipt_id: item?._id,
          });
          setShowDeleteReceipt(true);
        } else {
          toastr.error(res.data.message);
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const downloadPDF = (item) => {
    window.open(`${API_URL}receipt/view/` + item?.pdf_link);
    // saveAs(`${API_URL}` + item?.pdf_link, item?.receipt_no + ".pdf");
  };

  const handleOtpInputChange = (e) => {
    setReceiptOtpData({
      ...receiptOtpData,
      [e.target.name]: e.target.value,
    });
  };

  const verifyOtpForDelete = () => {
    if (receiptOtpData?.otp_gen != receiptOtpData?.otp) toastr.error("OTP is incorrect");
    else {
      setdisabled(true)
      let data = {
        user_id: userId,
        receipt_id: receiptOtpData?.receipt_id,
        remarks: remarks,
      };
      axios
        .post(
          `${API_URL}api/staff/delete_receipt`,
          data,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            handleTableData();
            setReceiptOtpData({});
            setShowDeleteReceipt(false);
            reset()

          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        }).finally(() => {
          setdisabled(false)
        })
    }
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}receipt/stafflist?localbody=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    // handleTableData(date1, date2, ward_id, group_id, staff_id);
  };

  const handleSelectChange = (selected, name) => {
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    // let date2 = searchData?.to_date ? searchData.to_date : "";
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          ["group_id"]: "",
        });
        setSelectedGroup(null);
        axios
          .get(`${API_URL}api/staff/groups?localbody_id=` + currentLocalbody + "&ward_id=" + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        // handleTableData(date1, date2, selected.value, "", staff_id);
        break;
      case "group":
        setSelectedGroup(selected);
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        // handleTableData(date1, date2, ward_id, selected.value, staff_id);
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(date1, date2, ward_id, group_id, selected.value);
        break;
      case "customer":
        setSelectedCustomer(selected);
        setMaster({
          ...master,
          customer_id: selected.value,
        });
        setErrors({
          ...errors,
          customerError: "",
        });
        break;
      case "payMethod":
        setSelectedPaymentMethod(selected);
        setMaster({
          ...master,
          receipt_payment_type: selected.value,
        });
        setErrors({
          ...errors,
          paymodeError: "",
        });
        break;
      default:
        break;
    }
  };
  const handleSearch = () => {
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let search = searchData?.searcg ? searchData?.search : ""
    handleTableData(date1, date2, ward_id, group_id, staff_id, search);
  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    handleTableData();
  };


  const handleInputChange = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidSubmit = () => {
    if (selectedCustomer === null || selectedPaymentMethod === null) {
      let errorVal = errors;
      if (selectedCustomer === null) {
        errorVal.customerError = "Please select customer";
      }
      if (selectedPaymentMethod === null) {
        errorVal.paymodeError = "Please select payment method";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      axios
        .post(`${API_URL}api/staff/add_reciept`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true && res.data.message) {
            toastr.success(res.data.message);
            formRef.current.reset();
            setSelectedCustomer(null);
            setSelectedPaymentMethod(null);
            setMaster({
              receipt_type: 0,
              user_id: userId,
            });
            setErrors({
              customerError: "",
              paymodeError: "",
            });
            handleTableData();
          } else {
            toastr.error(res.data?.message, "Failed to add receipt");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  };


  function handleTableData() {

    axios
      .get(`${API_URL}api/staff/delete/receipt/list?localbodyId=${currentLocalbody}&page=${page}&limit=${limit}&search=${searchData?.search}&staff_id=${searchData?.staff_id}&group_id=${searchData?.group_id}&ward_id=${searchData?.ward_id}&to_date=${searchData?.to_date}&from_date=${searchData?.from_date}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log(res, "deleted receipt");
        var result = res.data.data;
        setCount(res.data.count)
        result.map((item, index) => {
          item.id = index + 1
          item.date = item?.createDate ? moment(item.createDate).format("DD-MM-YYYY") : "---"
          item.custName = item?.receipt_cust_id?.cust_name
          item.custPhone = item?.receipt_cust_id?.cust_phone
          item.customerward = item?.receipt_ward_id?.ward_name

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}></i>

              {/* <i
                className="fas fa-print"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  downloadPDF(item);
                }}></i> */}

              {/* <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  showDeleteForm(item);
                }}></i> */}
            </div>
          );


          return item

        })

        setReceiptsForTable(result)


      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Receipt No",
        field: "receipt_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name	",
        field: "custName",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "ID",
      //   field: "customerId",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Mobile",
        field: "custPhone",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Group",
      //   field: "customergroup",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Ward",
        field: "customerward",
        sort: "asc",
        width: 200,
      },

      {
        label: "Amount",
        field: "reciept_amount",
        sort: "asc",
        width: 200,
      },

      {
        label: "Remarks",
        field: "remark",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: receiptsForTable,
  };
  const resetForm = () => {
    formRef.current.reset();
    setSelectedCustomer(null);
    setSelectedPaymentMethod(null);
    setMaster({
      receipt_type: 0,
      user_id: userId,
    });
    setErrors({
      customerError: "",
      paymodeError: "",
    });
  };

  function remarkChange(e) {
    setremarks(e.target.value)
  }
  function handleChange(e) {
    setPage(1)
    setSearchData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }


  return (
    <>
      <Modal show={form} toggle={() => setForm(!form)} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Receipt Details </h5>
          <button
            type="button"
            onClick={() => {
              // setForm(false);
              handleCloseForm();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            {/* <table
                id="product_available_day"
                className="table table-bordered dataTable"
              >
                <tr>
                  <th>Date :</th>
                  <td>{tableData[0]?.date}</td>
                  <th style={{ width: "160px" }}>Time :</th>
                  <td style={{ width: "160px", textAlign: "left" }}>
                    {tableData[0]?.time}
                  </td>
                </tr>
                <tr>
                  <th>Receipt No :</th>
                  <td>{tableData[0]?.receiptNo}</td>
                  <th>Customer Id :</th>
                  <td style={{ textAlign: "left" }}>
                    {tableData[0]?.customerId}
                  </td>
                </tr>
                <tr>
                  <th>Name :</th>
                  <td>{tableData[0]?.custName}</td>
                  <th>Group :</th>
                  <td style={{ textAlign: "left" }}>
                    {tableData[0]?.customergroup}
                  </td>
                </tr>
                <tr>
                  <th>Ward :</th>
                  <td>{tableData[0]?.customerward}</td>
                  <th>Amount :</th>
                  <td style={{ textAlign: "left" }}>{tableData[0]?.Amount}</td>
                </tr>
                <tr>
                  <th>DueAmount :</th>
                  <td>{tableData[0]?.dueAmount}</td>
                  <th>Staff :</th>
                  <td style={{ textAlign: "left" }}>{tableData[0]?.staff}</td>
                </tr>
              </table> */}
            <Row>
              <Col md="12" style={{ textAlign: "center", marginTop: "30px" }}>
                <p>
                  <h2>
                    {popupData?.localbodyName} {popupData?.localbodyType}{" "}
                  </h2>
                </p>
                <p>
                  <h5 style={{ marginTop: "-10px" }}>{"Haritha Karma Sena"}</h5>
                </p>
              </Col>
              {/* <Col style={{ marginTop: "30px" }}>
                  <span className="logo-lg">
                    <img
                      src={logoDark}
                      style={{ width: "200px" }}
                      alt=""
                      className="img-fluid"
                    />
                  </span>
                </Col> */}
            </Row>
            <div
              style={{
                borderWidth: 0.3,
                borderStyle: "dashed",
                borderRadius: 1,
                borderColor: "black",
                width: "90%",
                marginLeft: "42px",
              }}></div>
            <p style={{ marginLeft: "40px", marginTop: "20px" }}>
              CUSTOMER NAME : <span style={{ fontWeight: "700" }}>{popupData?.receipt_customer_name}</span>
            </p>
            <p style={{ marginLeft: "40px", marginTop: "10px" }}>
              BUILDING NO : <span style={{ fontWeight: "700" }}>{popupData?.cust_house_num}</span>
            </p>
            <p style={{ marginLeft: "40px", marginTop: "10px" }}>
              WARD NO : <span style={{ fontWeight: "700" }}>{popupData?.wardName}</span>
            </p>
            {popupData?.shop_name ? (
              <p style={{ marginLeft: "40px", marginTop: "10px" }}>
                COMMERCIAL NAME : <span style={{ fontWeight: "700" }}>{popupData?.shop_name}</span>
              </p>
            ) : (
              ""
            )}
            <p style={{ marginLeft: "40px", marginTop: "10px" }}>
              ADDRESS : <span style={{ fontWeight: "700" }}>{popupData?.cust_address}</span>
            </p>
            <p style={{ marginLeft: "40px", marginTop: "10px" }}>
              CUSTOMER ID : <span style={{ fontWeight: "700" }}>{popupData?.cust_reg_no}</span>
            </p>
            <div
              style={{
                borderWidth: 0.3,
                borderStyle: "dashed",
                borderRadius: 1,
                borderColor: "black",
                width: "90%",
                marginLeft: "42px",
              }}></div>
            <h5
              style={{
                textAlign: "center",
                marginTop: "8px",
                fontWeight: "600",
              }}>
              PREVIOUS DETAILS
            </h5>
            <div
              style={{
                borderWidth: 0.3,
                borderStyle: "dashed",
                borderRadius: 1,
                borderColor: "black",
                width: "90%",
                marginLeft: "42px",
              }}></div>
            <h5
              style={{
                textAlign: "center",
                marginTop: "10px",
                // marginBottom: "8px",
                fontWeight: "600",
              }}>
              INVOICE DETAILS FOR THE MONTH OF {popupData?.month}
            </h5>
            <table
              id="table2"
              style={{
                marginLeft: "41px",
                marginRight: "38px",
                marginTop: "20px",
                marginBottom: "25px",
              }}>
              <thead>
                <tr>
                  <th style={{ width: "150px" }}>INV AMOUNT</th>
                  <th style={{ width: "150px" }}>RECEIPT AMOUNT</th>
                  <th style={{ width: "150px" }}>BALANCE</th>
                  <th style={{ width: "150px" }}>RECEIPT DATE</th>
                  <th style={{ width: "150px" }}>RECEIPT NO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "right" }}>{popupData?.invoice_total_amt}</td>
                  <td style={{ textAlign: "right" }}>{ }</td>
                  <td style={{ textAlign: "right" }}>{ }</td>
                  <td style={{ textAlign: "center" }}>{ }</td>
                  <td style={{ textAlign: "center" }}>{ }</td>
                </tr>
              </tbody>
            </table>
            {/* <Row>
                <Col md="6" style={{ marginLeft: "40px", marginTop: "20px" }}>
                  <p>
                    INVOICE NUMBER :{" "}
                    <span style={{ fontWeight: "700" }}>
                      {popupData?.invoice_no}
                    </span>
                  </p>
                </Col>
                <Col>
                  <p style={{ marginTop: "20px" }}>
                    INVOICE DATE : {popupData?.invoice_date}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p style={{ marginLeft: "40px" }}>
                    INVOICE PERIOD : {popupData?.periodFrom} to{" "}
                    {popupData?.periodTo}
                  </p>
                </Col>
                <Col>
                  <p style={{ marginLeft: "40px" }}>
                    INVOICE AMOUNT : {popupData?.invoice_total_amt}
                  </p>
                </Col>
              </Row> */}
            <div
              style={{
                borderWidth: 0.3,
                borderStyle: "dashed",
                borderRadius: 1,
                borderColor: "black",
                width: "90%",
                marginLeft: "42px",
              }}></div>
            {/* <h5
                style={{
                  marginLeft: "40px",
                  marginTop: "10px",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                RECEIPT NO : {popupData?.receipt_no}
              </h5>
              <div
                style={{
                  borderWidth: 0.3,
                  borderStyle: "dashed",
                  borderRadius: 1,
                  borderColor: "black",
                  width: "90%",
                  marginLeft: "42px",
                }}
              ></div> */}

            <table
              id="table1"
              style={{
                marginLeft: "41px",
                marginRight: "38px",
                marginTop: "25px",
                marginBottom: "25px",
              }}>
              <thead>
                <tr>
                  <th style={{ width: "549px" }}>ITEM</th>
                  {/* <th>AMOUNT</th>
                    <th>BAGS</th> */}
                  <th>WEIGHT</th>
                  <th style={{ width: "140px" }}>CHARGES</th>
                </tr>
              </thead>

              <tbody>
                {wasteItems &&
                  wasteItems.map((item) => {
                    return (
                      <tr>
                        <td>{item?.waste_clt_item_name}</td>
                        {/* <td>{item?.waste_clt_item_price}</td>
                          <td>{item?.waste_clt_item_no_bag}</td> */}
                        <td>{item?.waste_clt_item_no_kg}</td>
                        <td style={{ textAlign: "right" }}>{item?.waste_clt_item_amt}</td>
                      </tr>
                    );
                  })}
                <tr>
                  <td style={{ textAlign: "left" }}>FIXED CHARGES</td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>{popupData?.receipt_fixed_charge}</td>
                </tr>
                {/* <tr>
                    <th  style={{ textAlign: "right" }}>
                      TOTAL CURRENT USAGE
                    </th>
                    <th></th>
                    <th style={{ textAlign: "right" }}>
                      {popupData?.receipt_tot_current_usage}
                    </th>
                  </tr> */}
                <tr>
                  <td style={{ textAlign: "left" }}>PREVIOUS DUE</td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>{popupData?.receipt_prev_due}</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>TOTAL DUE</td>
                  <td></td>
                  <td style={{ textAlign: "right" }}>{popupData?.receipt_tot_due}</td>
                </tr>
                {/* <tr>
                    <th colSpan={4} style={{ textAlign: "right" }}>
                      PAID
                    </th>
                    <th style={{ textAlign: "right" }}>
                      {popupData?.reciept_amount}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={4} style={{ textAlign: "right" }}>
                      BALANCE
                    </th>
                    <th style={{ textAlign: "right" }}>
                      {popupData?.receipt_due_amt}
                    </th>
                  </tr> */}
              </tbody>
            </table>

            <div
              style={{
                borderWidth: 0.3,
                borderStyle: "dashed",
                borderRadius: 1,
                borderColor: "black",
                width: "90%",
                marginLeft: "42px",
              }}></div>

            <h5
              style={{
                textAlign: "center",
                marginTop: "8px",
                fontWeight: "600",
              }}>
              RECEIPT
            </h5>

            <div
              style={{
                borderWidth: 0.3,
                borderStyle: "dashed",
                borderRadius: 1,
                borderColor: "black",
                width: "90%",
                marginLeft: "42px",
              }}></div>

            <Row>
              <Col md="3" style={{ marginTop: "20px" }}>
                <p style={{ marginLeft: "40px" }}>
                  RECEIPT NO : <span style={{ fontWeight: "700" }}>{popupData?.receipt_no}</span>
                </p>
              </Col>
              <Col md="3" style={{ marginTop: "20px" }}>
                <p style={{ marginLeft: "40px" }}>
                  RECEIPT AMOUNT : <span style={{ fontWeight: "700" }}>{popupData?.reciept_amount}</span>
                </p>
              </Col>
              <Col md="3" style={{ marginTop: "20px" }}>
                <p style={{ marginLeft: "40px" }}>
                  BALANCE AFTER PAY : <span style={{ fontWeight: "700" }}>{popupData?.receipt_due_amt}</span>
                </p>
              </Col>
              <Col md="3" style={{ marginTop: "20px" }}>
                <p style={{ marginLeft: "40px" }}>
                  DATE&TIME : <br />
                  <span style={{ fontWeight: "700" }}>
                    {popupData?.receipt_date}
                    {","}
                    {popupData?.receipt_time}
                  </span>
                </p>
              </Col>
              {/* <Col md="3" style={{ marginTop: "20px" }}>
                  <p style={{ marginTop: "40px"}}>
                     DELETE DATE: {popupData?.receipt_deleted_date}
                    
                  </p>
                </Col> */}
            </Row>
            <Row>
              <Col md="3" style={{ marginTop: "20px" }}>
                <p style={{ marginLeft: "40px" }}>
                  AMOUNT IN WORDS : <span style={{ fontWeight: "700" }}>{popupData?.amountinwords}</span>
                </p>
              </Col>
              <Col md="3" style={{ marginTop: "20px" }}>
                <p style={{ marginLeft: "40px" }}>
                  COLLECTED BY : <span style={{ fontWeight: "700" }}>{popupData?.addedBy}</span>
                </p>
              </Col>
              <Col md="3" style={{ marginTop: "20px" }}>
                <div hidden={!popupData?.receipt_deleted_dates}>
                  <p style={{ marginLeft: "40px", width: "100px" }}>
                    DELETED DATE: <span style={{ fontWeight: "700" }}>{popupData?.receipt_deleted_dates}</span>
                  </p>
                </div>
              </Col>
            </Row>
            {/* <Row>
                <Col>
                  <p style={{ textAlign: "center" }}>
                    Received {popupData?.reciept_amount}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col style={{ marginRight: "40px" }}>
                  <p
                    style={{
                      marginTop: "20px",
                      textAlign: "right",
                      marginTop: "-18px",
                    }}
                  >
                    COLLECTED BY
                  </p>
                  <p style={{ textAlign: "right", marginTop: "-10px" }}>
                    {popupData?.addedBy}
                  </p>
                </Col>
              </Row> */}
            <Row>
              <Col style={{ textAlign: "center", marginTop: "30px" }}>
                <p>
                  <h5 style={{ marginTop: "-23px", marginBottom: "-16px" }}>
                    Powered by Nellikka
                    <img src={logoSm} style={{ width: "35px", marginLeft: "10px" }} alt="" className="img-fluid" />
                  </h5>
                </p>
              </Col>
            </Row>

            {/* <hr
                style={{
                  color: "black",
                  backgroundColor: "black",
                  height: "5",
                }}
              />
              <div style={{ padding: "10px" }}>
                <h5 style={{ textAlign: "center" }}>
                  A {popupData?.localbody_Name} {popupData?.localbody_Type}{" "}
                  INITIATIVE
                </h5>
                <h5 style={{ textAlign: "center" }}>
                  UNDER 2022-23 ANNUAL PLAN
                </h5>
              </div> */}
          </div>
        </AvForm>
      </Modal>
      <div className='page-content'>
        <div className='container-fluid'>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input className="form-control" type="date" id="from_date" name="from_date" value={searchData?.from_date} onChange={handleDate} />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          name="group_id"
                          value={selectedGroup}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="staff_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "staff");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Customer</Label>
                        <Input
                          name="search"
                          value={searchData?.search}
                          className="form-control"
                          placeholder="Search Customer"
                          onChange={handleChange}
                        />
                      </div>
                    </Col>

                    <Col md="3">
                      <div style={{ display: "flex" }}>
                        {/* <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button color="primary" type="submit" onClick={() => handleSearch()}>
                            {"  "}
                            Search
                          </Button>
                        </div> */}

                        <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button color="danger" type="submit" onClick={() => reset()}>
                            {"  "}
                            Reset
                          </Button>
                        </div>

                        {/* <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <LoadingButton loading={isExporting} onClick={handleExport}>
                            Export
                          </LoadingButton>
                        </div> */}
                      </div>
                    </Col>


                  </Row>


                  <DataTable
                    data={data}
                    count={count}
                    page={page}
                    limit={limit}
                    onPageChange={(e) => setPage(e)}
                    onLimitChange={(e) => setLimit(e)}
                    id="receiptId"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>
    </>
  )
}

export default DeletedReceipt
