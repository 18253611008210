import React from 'react'
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap'
import Breadcrumb from '../../components/Common/Breadcrumb'
import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DataTable from '../../components/Common/DataTable';

const VehicleDetail = () => {

    const data = {
        columns: [
          {
            label: "#",
            field: "id",
            sort: "asc",
            width: 150,
          },
          {
            label: "Model",
            field: "date",
            sort: "asc",
            width: 400,
          },
          {
            label: "RTO Reg.no",
            field: "receipt_no",
            sort: "asc",
            width: 200,
          },
          {
            label: "Body	",
            field: "custName",
            sort: "asc",
            width: 200,
          },
          // {
          //   label: "ID",
          //   field: "customerId",
          //   sort: "asc",
          //   width: 200,
          // },
          {
            label: "Capacity",
            field: "custPhone",
            sort: "asc",
            width: 200,
          },
          // {
          //   label: "Group",
          //   field: "customergroup",
          //   sort: "asc",
          //   width: 200,
          // },
          {
            label: "Validity",
            field: "customerward",
            sort: "asc",
            width: 200,
          },
    
          {
            label: "Email",
            field: "reciept_amount",
            sort: "asc",
            width: 200,
          },
    
          {
            label: "Mobile",
            field: "remark",
            sort: "asc",
            width: 200,
          },
          {
            label: "Action",
            field: "action",
            sort: "asc",
            width: 200,
          },
        ],
        rows: [],
      };
    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                <Breadcrumb breadcrumbItem="Vehicle Details"  title="Home"/>
                   <Row>
                   <Card>
                    <CardBody>
                        <AvForm>
                        <Row>
                            <Col md="2" className='mt-3'> 
                            <Label>Vehicle model</Label> 
                            <AvField name="vehiclemodel" placeholder="Vehicle model"/>
                            </Col>
                            <Col md="3" className='mt-3'> 
                            <Label>RTO Reg.No</Label> 
                            <AvField name="rtoref" placeholder="RTO Reg.no"/>
                            </Col>
                            <Col md="2" className='mt-3'> 
                            <Label>Body Type</Label> 
                            <AvField name="rtoref" placeholder="Body Type"/>
                            </Col>
                            <Col md="2" className='mt-3'> 
                            <Label>Photo</Label> 
                            <AvField name="rtoref" type="file"
                            />
                            </Col>
                            <Col md="2" className='mt-3'> 
                            <Label>Insurance</Label> 
                            <AvField name="rtoref" placeholder="Insurance"/>
                            </Col>
                            <Col md="2" className='mt-3'> 
                            <Label>Gps Details</Label> 
                            <AvField name="rtoref" placeholder="Gps Details"/>
                            </Col>
                            <Col md="2" className='mt-3'> 
                            <Label>Inspection Details</Label> 
                            <AvField name="rtoref" placeholder="Inspection Details"/>
                            </Col>
                            <Col md="2" className='mt-3'> 
                            <Label>Capacity</Label> 
                            <AvField name="rtoref" placeholder="Capacity"/>
                            </Col>
                            <Col md="2" className='mt-3'> 
                            <Label>Manufacturing Year</Label> 
                            <AvField name="rtoref" placeholder="Manufacturing Year"/>
                            </Col>
                            <Col md="2" className='mt-3'> 
                            <Label>Validity</Label> 
                            <AvField name="rtoref" placeholder="Validity"/>
                            </Col>
                        </Row>
                        <Row>
                            <h6 className='mt-5'>Sender Details</h6>
                            <Col md="3" className='mt-2'> 
                            <Label>Email</Label> 
                            <AvField name="rtoref" placeholder="Email"/>
                            </Col>
                            <Col md="3" className='mt-2'> 
                            <Label>Mobile</Label> 
                            <AvField name="rtoref" placeholder="Mobile"/>
                            </Col>
                            <Col md="3" className='mt-2'> 
                            <Label>Address</Label> 
                            <AvField rows={1} name="rtoref" type="textarea" placeholder="Address"/>
                            </Col>

                            <Col md="3" className='mt-4'> 
                            <Button className='mt-3' color='primary' type='submit'>Submit</Button>
                            </Col>

                        </Row>

                        </AvForm>
                       
                      
                    </CardBody>
                   </Card>

                   </Row>
                   <Row>
                   <DataTable
                    data={data}
                    count={2}
                    page={1}
                    id="receiptId"
                  />
                   </Row>
                  
                </div>
            </div>

        </>
    )
}

export default VehicleDetail
